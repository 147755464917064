import create from 'zustand';

interface IAccountStore {
  accounts: Array<any>;
  setAccounts: (data: any) => void;
}

const AccountStore = create<IAccountStore>((set) => ({
  accounts: [],
  setAccounts: (data) => set({ accounts: data }),
}));

export default AccountStore;

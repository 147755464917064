/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { signOut } from 'firebase/auth';
import { auth } from 'src/firebase';
import UserStore from 'src/stores/UserStore';
import AccountStore from 'src/stores/AccountStore';

function Navbar() {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = UserStore();
  const [user, setUser] = useState({ type: null });
  const { accounts } = AccountStore();

  // Get type of current user
  useEffect(() => {
    if (accounts.length > 0) {
      setUser(accounts.find((account) => account.id === currentUser.uid));
    }
  }, [accounts]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setCurrentUser(null);
        navigate('/');
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <Disclosure as="nav" className="bg-yellow fixed right-0 left-0 top-0 z-10">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open main menu</span>
              <svg
                className="fill-current h-5 w-5"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path
                  d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                  fill="#fff"
                />
              </svg>
            </Disclosure.Button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <img
              className="w-24"
              src="/assets/solx-logo-white.png"
              alt="SolX"
            />
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4 lg:mr-20">
                <Link
                  to="/tickets"
                  className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  TICKETS
                </Link>
                {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager' ? (
                  <>
                    <Link
                      to="/documents"
                      className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      DOCUMENTS
                    </Link>
                    <Link
                      to="/accounts"
                      className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      ACCOUNTS
                    </Link>
                  </>
                ) : null}

                {/* <Link
                  to="/faqs"
                  className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  FAQS
                </Link> */}
              </div>
            </div>

            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="flex px-3 py-2 rounded-md text-sm text-white font-raleway focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  {currentUser.email}
                  <ChevronDownIcon
                    className="w-5 h-5 ml-2 -mr-1 text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md border-2 border-white shadow-lg py-2 bg-yellow focus:outline-none">
                  {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager' ? (
                    <Menu.Item>
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-sm text-white font-raleway"
                      >
                        CREATE PROFILE
                      </Link>
                    </Menu.Item>
                  ) : null}

                  <Menu.Item>
                    <button
                      type="button"
                      className="w-full block px-4 py-2 text-sm text-white text-left font-raleway"
                      onClick={() => navigate(`/profile/${currentUser.uid}`)}
                    >
                      UPDATE PROFILE
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      type="button"
                      className="w-full block px-4 py-2 text-sm text-white text-left font-raleway"
                      onClick={handleSignOut}
                    >
                      SIGN OUT
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <Disclosure.Panel className="sm:hidden">
        <div className="flex flex-col px-2 pt-2 pb-3 space-y-1">
          <Disclosure.Button className="flex">
            <Link
              to="/tickets"
              className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway"
            >
              Tickets
            </Link>
          </Disclosure.Button>
          {user.type === 'SolX - Admin' || user.type === 'SolX - Account Manager' ? (
            <>
              <Disclosure.Button className="flex">
                <Link
                  to="/documents"
                  className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway"
                >
                  DOCUMENTS
                </Link>
              </Disclosure.Button>
              <Disclosure.Button className="flex">
                <Link
                  to="/accounts"
                  className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway"
                >
                  ACCOUNTS
                </Link>
              </Disclosure.Button>
            </>
          ) : null}

          {/* <Disclosure.Button className="flex">
            <Link
              to="/"
              className="text-white px-3 py-2 rounded-md text-sm font-medium font-raleway"
            >
              FAQS
            </Link>
          </Disclosure.Button> */}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export default Navbar;

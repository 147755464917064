import React from 'react';

interface ISearchBar {
  value: string;
  placeholder: string;
  onChange: (event: any) => void;
}

function SearchBar({ value, placeholder, onChange }: ISearchBar) {
  return (
    <div className=" w-fit pt-2 relative text-black">
      <input
        className="border bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        type="text"
        name="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />

      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-0 top-0 mt-5 mr-4 h-4 w-4 fill-current"
      >
        <path
          d="M19.74 18.7604L16.0189 15.0479C17.3322 13.4843 17.9912 11.4741 17.8584 9.43648C17.7256 7.39887 16.8113 5.49119 15.3062 4.11131C13.8011 2.73143 11.8213 1.98589 9.77989 2.03019C7.73844 2.07448 5.79291 2.90518 4.34904 4.34904C2.90518 5.79291 2.07448 7.73844 2.03019 9.77989C1.98589 11.8213 2.73143 13.8011 4.11131 15.3062C5.49119 16.8113 7.39887 17.7256 9.43648 17.8584C11.4741 17.9912 13.4843 17.3322 15.0479 16.0189L18.7604 19.74C18.8918 19.8671 19.0674 19.9381 19.2502 19.9381C19.433 19.9381 19.6086 19.8671 19.74 19.74C19.8691 19.6097 19.9416 19.4337 19.9416 19.2502C19.9416 19.0667 19.8691 18.8907 19.74 18.7604ZM3.43769 9.96895C3.43769 8.67719 3.82075 7.41444 4.53841 6.34038C5.25607 5.26632 6.27611 4.42919 7.46954 3.93486C8.66298 3.44052 9.97619 3.31118 11.2431 3.56319C12.5101 3.8152 13.6738 4.43724 14.5872 5.35065C15.5007 6.26407 16.1227 7.42782 16.3747 8.69476C16.6267 9.9617 16.4974 11.2749 16.003 12.4683C15.5087 13.6618 14.6716 14.6818 13.5975 15.3995C12.5235 16.1171 11.2607 16.5002 9.96895 16.5002C8.23745 16.4979 6.57752 15.8091 5.35317 14.5847C4.12881 13.3604 3.43997 11.7004 3.43769 9.96895Z"
          fill="#FBB216"
        />
      </svg>
    </div>
  );
}

export default SearchBar;

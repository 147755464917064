import create from 'zustand';

interface ICompanyStore {
  companies: Array<any>;
  setCompanies: (data: any) => void;
}

const CompanyStore = create<ICompanyStore>((set) => ({
  companies: [],
  setCompanies: (data) => set({ companies: data }),
}));

export default CompanyStore;

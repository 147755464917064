import create from 'zustand';

interface IUserStore {
  currentUser: any;
  setCurrentUser: (data: any) => void;
  removeCurrentUser: () => void;
}

const UserStore = create<IUserStore>((set) => ({
  currentUser: null,
  setCurrentUser: (data: any) => {
    set({ currentUser: data });
  },
  removeCurrentUser: () => set({ currentUser: null }),
}));

export default UserStore;

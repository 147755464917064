import create from 'zustand';

interface ITicketStore {
  tickets: Array<any>;
  setTickets: (data: any) => void;
}

const TicketStore = create<ITicketStore>((set) => ({
  tickets: [],
  setTickets: (data) => {
    set({ tickets: data });
  },
}));

export default TicketStore;
